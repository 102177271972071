<template>
    <div>
        <div class="files-new-view" :class="{ 'files-new-view__short': shortFile, 'files-new-view__small': small }">
            <FileNewView
                v-for="(file, idx) in filesList"
                :key="'file-new-view-idx-' + idx + (file && file.url)"
                :file="files.find(f => f.uuid === file.uuid)"
                :parent="parent"
                :shortFile="shortFile"
                @save="$emit('save')"
                @file:delete="openRemoveModal"
            />
            <ImageNewView
                v-for="(file, idx) in filesImage"
                :key="'image-new-view-idx-' + idx + (file && file.url)"
                :file="files.find(f => f.uuid === file.uuid)"
                :parent="parent"
                :shortFile="shortFile"
                @file:delete="openRemoveModal"
            />
        </div>
        <FilePreview
            class="mt-20"
            :key="index"
            v-for="(document, index) in documents"
            :file="files.find(f => f.uuid === document.uuid)"
        />
    </div>
</template>

<script>
import axios from "~axios"
import FilePreview from "@components/Files/FilePreview.vue"
import FileNewView from "@components/Files/FileNewView.vue"
import ImageNewView from "@components/Files/ImageNewView.vue"
//
export default {
    name: "FilesNewView",
    components: {
        FilePreview,
        ImageNewView,
        FileNewView
    },
    props: {
        files: {
            type: Array,
            default: () => []
        },
        shortFile: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        updatePreview: {
            type: Boolean,
            default: true
        },
        parent: {
            type: String,
            default: "" // => "lesson"
        }
    },
    created() {
        if (this.updatePreview) {
            let checkStatus = setInterval(() => {
                for (const [index, file] of this.files.entries()) {
                    const { uuid, url } = file
                    if (!url) {
                        axios.get(`/files/${uuid}`).then(response => {
                            let data = response.data.data
                            if (data.process_status === "completed" && !data.url.includes("fileservice")) {
                                this.files[index] = {
                                    ...this.files[index],
                                    url: data.url
                                }
                                this.$forceUpdate()
                                this.$emit("save")
                            }
                        })
                    }
                }
                if (this.files.filter(f => f.url).length === this.files.length) {
                    clearInterval(checkStatus)
                    this.$forceUpdate()
                }
            }, 2000)
        }
    },
    methods: {
        openRemoveModal(file) {
            this.$emit("openRemoveModal", file)
        },
        isImageFile(file) {
            const validMimeTypes = [
                'image/jpeg',
                'image/png',
                'image/gif',
                'image/webp',
                'image/svg+xml',
                'image/apng',
                'image/x-icon',
                'image/bmp'
            ];

            return validMimeTypes.includes(file.mime_type)
        }
    },
    computed: {
        documents() {
            return this.files.filter(
                file =>
                    (file.mime_type.includes("application/pdf") ||
                        file.extension === "dox" ||
                        file.extension === "ppt" ||
                        file.extension === "pptx" ||
                        file.extension === "docx") &&
                    (file.allow_doc_preview || (file.pivot && file.pivot.allow_doc_preview)) &&
                    this.parent !== "expert"
            )
        },
        filesList() {
            return this.files.filter(f => !this.isImageFile(f))
        },
        filesImage() {
            return this.files.filter(f => this.isImageFile(f))
            /*return this.files.filter(f => f.mime_type.match("image"))*/
        }
    }
}
</script>

<style scoped lang="sass">
.files-new-view
    display: flex
    align-items: self-start
    flex-wrap: wrap
    width: 100%
    gap: 10px
    &::v-deep
       .spin-loader
           display: block !important
    &__small
        &::v-deep
            .image-new-view__wrapper
                max-width: 30px
                height: auto
</style>
